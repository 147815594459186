import service from "@/services/campanhas-ura.js";

const state = {
  campanhas: [],
  carregando: false,
  page: 1,
  total_pages: 1,
};
const mutations = {
  setCampanhas(state, payload) {
    state.campanhas = payload;
  },
};
const actions = {
  async getCampanhas({ state, commit }, payload) {
    state.carregando = true
    try {
      const { data } = await service.get(payload);
      commit('setCampanhas', data.objects)
      state.page = data.page;
      state.total_pages = data.total_pages;
    } catch (error) {
      console.error(error);
    } finally {
      state.carregando = false
    }
  },
  async salvarCampanha({ state, commit }, payload) {
    try {
      let response = null
      let updatedCampanhas = []

      if (payload?.id) {
        response = await service.patch(payload)
        updatedCampanhas = state.campanhas.map(item => {
          if (item.id === response.data.id) {
            item = response.data
          }
          return item
        })
      } else {
        response = await service.post(payload)
        updatedCampanhas = [...state.campanhas]
        updatedCampanhas.push(response.data)
      }

      commit('setCampanhas', updatedCampanhas)

    } catch (error) {
      console.error(error);
    }
  },
};
const getters = {
  campanhas: (state) => state.campanhas,
  todasCampanhas: (state) => state.campanhas,
  carregando: (state) => state.carregando,
  page: (state) => state.page,
  total_pages: (state) => state.total_pages,
};

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
