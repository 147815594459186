import contratoService from "../../services/contratos";

const state = {
  contrato: null,
  carregandoContrato: false,
  erroContrato: false,
  historico: null,
};
const mutations = {
  setContrato: (state, payload) => {
    state.contrato = payload;
  },
  setCarregandoContrato: (state, payload) => {
    state.carregandoContrato = payload;
  },
  setErroContrato: (state, payload) => {
    state.erroContrato = payload;
  },
  setHistorico(state, historico) {
    state.historico = historico;
  },
};
const actions = {
  async getContrato({ commit }, payload) {
    commit("setContrato", null);
    commit("setCarregandoContrato", true);
    await contratoService
      .get(payload)
      .then((response) => {
        commit("setErroContrato", false);
        commit("setCarregandoContrato", false);
        commit("setContrato", response.data);
      })
      .catch((error) => {
        commit("setErroContrato", true);
      })
      .finally(() => {
        commit("setCarregandoContrato", false);
      });
  },

  async pegarHistorico({ state, commit, getters }, payload) {
    if (state.historico?.some((item) => item.contrato_id === payload)) {
      return getters.historico;
    }
    let resposta = await contratoService
      .pegarHistorico(payload)
      .then((retorno) => {
        if (retorno?.status == 200) {
          return retorno.data;
        } else {
          return null;
        }
      });

    commit("setHistorico", resposta);
    return resposta;
  },
};

const getters = {
  contrato: (state) => state.contrato,
  carregandoContrato: (state) => state.carregandoContrato,
  erroContrato: (state) => state.erroContrato,
  historico: (state) => state.historico,
};
export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
