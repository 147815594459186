export const rotas = [
    {
      path: "/campanhas-ura",
      name: "Campanhas Ura",
      component: () =>
        import(/* webpackChunkName: "console" */ "./pages/index.vue"),
      meta: {
        title: "Campanhas URA",
        precisaAutenticacao: true,
        precisaEmpresa: true
      },
    },
  ];
  