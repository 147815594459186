import http from "@/plugins/axios";
import oauth from "../services/oauth";
import enviroment from "@/assets/js/enviroment";
import * as Sentry from "@sentry/browser";
import Cookies from "js-cookie";


const state = {
  usuario: {
    empresas: [],
  },
  token: null,
};

const mutations = {
  setToken(state, token) {
    console.debug("Salvando o token de autenticação.");

    state.token = token;
    Cookies.set("melembreAuthToken", token, {
      domain: enviroment.cookiesDomain,
      path: "/",
    });
    http.setTokenCoreApi(token);
    http.setTokenDadosApi(token);

    let urlContinue = Cookies.get("urlContinue");

    if (urlContinue && urlContinue != "null") {
      Cookies.remove("urlContinue", {
        domain: enviroment.cookiesDomain,
        path: "/",
      });
      window.location = urlContinue;
    }
  },
  setUsuario(state, usuario) {
    console.debug("Salvando o usuário autenticado:", usuario.email);
    state.usuario = usuario;
    Cookies.set("melembreUsuario", JSON.stringify(usuario), {
      domain: enviroment.cookiesDomain,
      path: "/",
    });

    Sentry.setUser({
      id: usuario.id,
      email: usuario.email,
      username: usuario.nome,
    });
  },
};

const actions = {
  async auth({ commit, dispatch }, token) {
    if (!token) {
      console.error("Autenticação sem token.");
      await dispatch('logout')
    }

    try {
      commit("setToken", token);
      console.debug("Consultando o usuário autenticado.");

      const { data } = await oauth.me()
      console.debug("Usuário autenticado:", data.email);
      commit("setUsuario", data);
      commit("empresa/setEmpresas", data.empresas, { root: true });
    } catch (e) {
      console.error("Não foi possível buscar o usuário autenticado.", e);
      dispatch("logout");
    }

  },

  logout() {
    console.debug("Deslogando.");

    state.token = null;
    http.setTokenCoreApi(null);
    http.setTokenDadosApi(null);
    Cookies.remove("melembreAuthToken");
    Cookies.set("urlContinue", window.location.href, {
      domain: enviroment.cookiesDomain,
      path: "/",
    });
    window.location.href = enviroment.coreApiUrl + "/oauth/login";
  },

  async fetchMe({ commit, dispatch }) {
    try {
      const { data } = await oauth.me()
      commit("setUsuario", data);

      const chaveEmpresaSelecionada = Cookies.get('meLembreEmpresaSelecionada')
      const empresaSelecionada = data.empresas.find(e => e.chave === chaveEmpresaSelecionada) || data.empresas[0]

      await dispatch("empresa/setEmpresaSelecionada", empresaSelecionada, { root: true })

      commit("empresa/setEmpresas", data.empresas, { root: true })
    } catch (e) {
      console.error("Não foi possível obter os dados do usuário autenticado.", e);
      throw e;
    }
  },

  async verificarAutenticacao({ commit, dispatch }) {
    if (state.usuario && state.usuario.id && state.token) return true;

    const token = state.token || Cookies.get("melembreAuthToken");
    if (token) {
      commit("setToken", token);
      http.setTokenCoreApi(token);
      http.setTokenDadosApi(token);
    } else {
      console.debug("Token de autenticação não encontrado.");
      await dispatch('logout')
      return;
    }

    console.debug("Consultando o usuário autenticado.");
    try {
      const { data } = await oauth.me()
      commit("setUsuario", data);

      const chaveEmpresaSelecionada = Cookies.get('meLembreEmpresaSelecionada')
      const empresaSelecionada = data.empresas.find(e => e.chave === chaveEmpresaSelecionada) || data.empresas[0]

      await dispatch("empresa/setEmpresaSelecionada", empresaSelecionada, { root: true })

      commit("empresa/setEmpresas", data.empresas, { root: true })
    } catch (e) {
      console.error("Não foi possível buscar o usuário autenticado.", e);
      throw e;
    }

    return true;
  },
};

const getters = {
  getUsuario({ usuario }) {
    return usuario;
  },
  usuarioEmpresas({ usuario }) {
    return usuario.empresas.map(u => {
      return {
        nome: u.nome,
        chave: u.chave,
        id: u.id,
        url_instancia: u.url_instancia
      }
    });
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
