import http from "@/plugins/axios";
import store from "@/store";

export default {
  atualizar(id, payload) {
    let empresa = store.getters["empresa/empresaSelecionada"];
    return http.api.patch(`${empresa.url_instancia}/v1/cliente/${id}`, payload);
  },
  consultaClientesSemEmpresa(url_instancia, params) {
    return http.api.get(`${url_instancia}/v1/cliente`, { params });
  },
  consultaClientes(params) {
    let empresa = store.getters["empresa/empresaSelecionada"];
    return http.api.get(`${empresa.url_instancia}/v1/cliente`, { params });
  },
  getCliente(params, id) {
    let empresa = store.getters["empresa/empresaSelecionada"];    
    return http.api.get(`${empresa.url_instancia}/v1/cliente/${id}`, {
      params,
    });
  },
};
