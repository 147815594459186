import ContratosService from "@/services/contratos";
import LogService from "@/services/log-usuario";

const state = {
  contratos: {
    objects: [],
  },
  loading: false,
};
const mutations = {
  setContratos: (state, payload) => {
    state.contratos = payload;
  },
  setLoading: (state, payload) => {
    state.loading = payload;
  },
};
const actions = {
  async getLogContratos({ rootGetters }, payload) {
    const empresas = rootGetters["auth/usuarioEmpresas"]

    const buscaPromises = []
    empresas.forEach(e => {
      buscaPromises.push(LogService.logUsuarioSemEmpresa(e.url_instancia, payload))
    })

    const results = await Promise.allSettled(buscaPromises)
    const successfulResults = results
      .filter(result => result.status === "fulfilled")
    const failedResults = results
      .filter(result => result.status === "rejected")

    const contratos = successfulResults.map(r => {
      const url = r.value.config.url.replace("https://", "").split("/")[0];
      const empresa = url.split(".")[0];

      return {
        empresa: empresa,
        data: r.value.data
      }
    })

    const empresasComErro = failedResults.map(err => {
      const url = err.reason.config.url.replace("https://", "").split("/")[0];
      const empresa = url.split(".")[0];

      return {
        empresa: empresa
      }
    })

    return contratos
  },

  async getContratosMultiempresa({ commit, rootGetters }, payload) {
    const empresas = rootGetters["auth/usuarioEmpresas"]

    const buscaPromises = []
    empresas.forEach(e => {
      buscaPromises.push(ContratosService.searchSemEmpresa(e.url_instancia, payload))
    })

    const results = await Promise.allSettled(buscaPromises)
    const successfulResults = results
      .filter(result => result.status === "fulfilled")
    const failedResults = results
      .filter(result => result.status === "rejected")

    const contratos = successfulResults.map(r => {
      const url = r.value.config.url.replace("https://", "").split("/")[0];
      const empresa = url.split(".")[0];

      return {
        empresa: empresa,
        data: r.value.data.objects
      }
    })

    const empresasComErro = failedResults.map(err => {
      const url = err.reason.config.url.replace("https://", "").split("/")[0];
      const empresa = url.split(".")[0];

      return {
        empresa: empresa
      }
    })

    commit("setContratos", contratos);
  },

  async getContratos({ commit }, payload) {
    const { data } = await ContratosService.search(payload);
    commit("setContratos", data);
  },

  saveContrato({ commit, rootState }, payload) { },

  limparContratos({ commit }) {
    commit("setContratos", {
      objects: [],
    });
  },
};
const getters = {
  contratos: (state) => state.contratos,
  loading: (state) => state.loading,
};
export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
