import http from "@/plugins/axios";

export default {
  get(params) {
    return http.api.get("v1/campanha_ura", { params });
  },
  post(payload) {
    return http.api.post("v1/campanha_ura", payload);
  },
  patch(payload) {
    const id = payload.id
    delete payload["id"]
    
    return http.api.patch(`v1/campanha_ura/${id}`, payload);
  },
};
