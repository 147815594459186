import auth from "./auth.store";
import cliente from "./cliente.module";
import empresa from "./empresa.module";
import perfisEmpresa from "./perfisEmpresa.module";
import regua from "./regua";
import faq from "./faq.store";
import snackbar from "./snackBard.module";
import eventos from "@/views/reguas/store/eventos.module.js";
import reguas from "@/views/reguas/store/reguas.module.js";
import evento from "@/views/reguas/store/evento.module.js";
import contratos from "@/views/contratos/contratos.module.js";
import parcela from "@/store/parcela";
import contrato from "@/views/contrato/contrato.module.js";
import contato from "@/store/contato.module.js";
import agenda from "@/views/agenda/store/agenda.module.js";
import fatura from "@/views/fatura/store/fatura.module.js";
import geral from "@/store/geral.module.js";
import movimentacao from "@/store/movimentacao.module.js";
import tipoMovimentacao from "@/views/reguas/store/tipoMovimentacao.module.js";
import variavelRegua from "@/views/reguas/store/variavelRegua.module.js";
import filtros from "@/views/contrato/filtros.module.js";
import nps from "@/views/nps/store/nps.module.js";
import iniciativas from "@/views/iniciativas/store/iniciativas.module.js";
import pesquisas from "@/views/pesquisas/store/pesquisas.module.js";
import emails from "@/views/emails/store/emails.module.js";
import usuarios from "./usuarios.store";
import fluxosAutomaticos from "./fluxosAutomaticos.module";
import campanhas from "@/views/campanhas-ura/store/campanhas.module.js";


export default {
  auth,
  empresa,
  regua,
  snackbar,
  variavelRegua,
  reguas,
  eventos,
  evento,
  contratos,
  parcela,
  contrato,
  cliente,
  contato,
  agenda,
  fatura,
  geral,
  movimentacao,
  tipoMovimentacao,
  filtros,
  nps,
  iniciativas,
  pesquisas,
  emails,
  faq,
  usuarios,
  perfisEmpresa,
  fluxosAutomaticos,
  campanhas
};
